import React, { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import './date-picker.style.scss';
import InputError from 'ui-kit/input-error/input-error';
import { TextProps } from 'ui-kit/text/text.props';

interface DatePickerProps extends TextProps {
    onChange?: (event: React.ChangeEvent<any>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onOpen: React.MouseEventHandler<HTMLInputElement>;
    onClose: React.MouseEventHandler<HTMLInputElement>;
    identifier: string;
    labelFor: string;
    max: string;
    min: string;
}

const useListener = (ref: any, eventName: string, handler: Function) => {
    useEffect(() => {
        if (ref.current) {
            const element = ref.current;
            element.addEventListener(eventName, handler);
            return () => element.removeEventListener(eventName, handler);
        }
    }, [eventName, handler, ref]);
};

export const DatePicker = ({
    onChange,
    onFocus,
    onBlur,
    onOpen,
    onClose,
    identifier,
    labelFor,
    label,
    name,
    max,
    min,
    touched,
    errors,
    showErrorsText = true,
    ...props
}: DatePickerProps) => {
    const ref = useRef(null);

    useListener(ref, 'duetChange', onChange);
    useListener(ref, 'duetFocus', onFocus);
    useListener(ref, 'duetBlur', onBlur);
    useListener(ref, 'duetOpen', onOpen);
    useListener(ref, 'duetClose', onClose);
    const DATE_FORMAT_US = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const dateAdapter = {
        parse(value = '', createDate: Function) {
            const matches = value.match(DATE_FORMAT_US);

            if (matches) {
                return createDate(matches[3], matches[1], matches[2]);
            }
        },
        format(date: Date) {
            return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        }
    };
    useEffect(() => {
        if (ref.current) {
            const local = ref.current.localization;
            if (local) {
                local.placeholder = 'mm/dd/yyyy';
                local.locale = 'en-US';
            }
            ref.current.dateAdapter = dateAdapter;
        }
    }, [dateAdapter]);

    return (
        <div className={'text'}>
            <div className={`text-input-container date-picker`}>
                <Form.Label className={'date-picker-label'} htmlFor={labelFor}>
                    {label}
                </Form.Label>
                <duet-date-picker
                    name={name}
                    ref={ref}
                    max={max}
                    min={min}
                    required={true}
                    identifier={identifier}
                    {...props}
                ></duet-date-picker>
                <InputError
                    inputElement={ref}
                    touched={touched}
                    label={label}
                    errors={errors}
                    showErrors={showErrorsText}
                    className="text-errors"
                />
            </div>
        </div>
    );
};
